import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import {persistReducer} from "redux-persist";
import * as notificationsReducer from "./notifications";
import * as routinesReducer from "./routines";
import * as timetableReducer from "./timetable";
import * as userDataReducer from "./userData";
import * as sessionReducer from "./session";
import * as navigationReducer from "./navigation";
import * as deviceReducer from "./device";
import * as synchedDataReducer from "./synchedData";
import * as messagesReducer from "./messages";
import * as bookedClassesReducer from "./bookedClasses";

const rootPresistConfig = {
  key: "root",
  timeout: 10000,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["currentOrientation", "currentRoutine", "statusBarIsHidden"],
};

const rootReducer = combineReducers(
  Object.assign(
    notificationsReducer,
    routinesReducer,
    timetableReducer,
    userDataReducer,
    sessionReducer,
    navigationReducer,
    deviceReducer,
    synchedDataReducer,
    messagesReducer,
    bookedClassesReducer
  )
);

export default persistReducer(rootPresistConfig, rootReducer);
