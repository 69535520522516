export const genericBackgroundColor = "#0b1143";
export const genericTextInputStyle = {
  container: {
    flex: 1,
  },
  input: {
    color: "white",
    fontSize: 20,
    fontFamily: "lato-regular",
    outlineWidth: 0,
  },
};
export const textWithLinkStyle = {
  container: {flexDirection: "row", flex: 1},
  text: {flex: -1, marginRight: 5, fontSize: 16},
  link: {
    container: {
      flex: -1,
    },
    content: {
      fontSize: 16,
      fontFamily: "lato-bold",
    },
  },
};
export const containerPadding = 20;
