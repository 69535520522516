import React, {useState, useCallback} from "react";
import {
  FlatList,
  Text,
  TouchableOpacity,
  View,
  Platform,
  Dimensions,
} from "react-native";
import * as ApiHelper from "helpers/ApiHelper";
import LoadingSpinner from "components/presentationals/LoadingSpinner";
import RootWrapper from "components/presentationals/RootWrapper";
import {Screens, AnalitycsEventsLabels} from "config/settings";
import CentredWrapper from "components/presentationals/CentredWrapper";
import GenericText from "components/presentationals/GenericText";
import AnalyticsHelper from "helpers/AnalyticsHelper";
import Alert from "components/presentationals/Alert";
import {useFocusEffect} from "@react-navigation/native";
import {useDimensions} from "@react-native-community/hooks";

const styles = {
  item: {
    padding: 10,
    alignSelf: "center",
  },
  name: {
    color: "white",
  },
};

export default ({navigation, route}) => {
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const maxHeight = useDimensions().window.height - 50;

  useFocusEffect(
    useCallback(() => {
      AnalyticsHelper.setEvent(AnalitycsEventsLabels.recordedClasses);
      const fetchRecordedClasses = async () => {
        try {
          setLoading(true);
          const recordings = await ApiHelper.getRecordedClasses();
          setRecordings(recordings);
        } catch (e) {
          setErrorMessage(e);
        } finally {
          setLoading(false);
        }
      };
      setErrorMessage(null);
      if (route.params?.loadClasses) {
        fetchRecordedClasses();
      }
    }, [route.params])
  );

  if (loading) {
    return (
      <RootWrapper>
        <LoadingSpinner visible={loading} />
      </RootWrapper>
    );
  }

  const Item = ({name, classes}) => (
    <TouchableOpacity
      style={styles.item}
      onPress={() =>
        navigation.navigate(Screens.RecordedClassesByDate, {
          screen: Screens.RecordedClassesByDate,
          params: {classes, name},
        })
      }
    >
      <Text style={styles.name}>{name}</Text>
    </TouchableOpacity>
  );
  let filteredRecordings = [];
  recordings.forEach(({name}) => {
    const searchedClassName = name.substring(0, name.indexOf("-")).trim();
    const classesByName = recordings.filter(
      ({name}) =>
        name.substring(0, name.indexOf("-")).trim() === searchedClassName.trim()
    );
    if (
      !filteredRecordings.find(
        (item) => item.name.trim() === searchedClassName.trim()
      )
    ) {
      filteredRecordings = [
        ...filteredRecordings,
        {name: searchedClassName.trim(), classes: classesByName},
      ];
    }
    filteredRecordings.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  });

  return (
    <RootWrapper style={{padding: 0, maxHeight}}>
      {filteredRecordings.length > 0 ? (
        <>
          <Text
            style={{
              color: "white",
              alignSelf: "center",
              marginVertical: 10,
              fontSize: 18,
              fontFamily: "lato-regular",
            }}
          >
            CLASES GRABADAS
          </Text>
          <CentredWrapper>
            <FlatList
              data={filteredRecordings}
              renderItem={({item}) => (
                <Item name={item.name} classes={item.classes} />
              )}
              keyExtractor={() => Math.random().toString()}
              style={{marginTop: 20}}
            />
          </CentredWrapper>
        </>
      ) : (
        <View style={{flex: 1, alignItems: "center", marginTop: 50}}>
          <GenericText
            style={{
              fontFamily: "brother-1816-bold",
              fontSize: 22,
              color: "white",
              flex: -1,
            }}
          >
            ¡Ups!
          </GenericText>
          <GenericText style={{fontSize: 18}}>
            No hay clases grabadas aun!
          </GenericText>
        </View>
      )}
      <Alert
        show={!!errorMessage}
        message={errorMessage}
        onConfirmPressed={() => {
          navigation.navigate(Screens.Home);
          setErrorMessage(null);
        }}
        contentContainerStyle={
          Platform.OS === "web"
            ? {
                left: __DEV__ ? Dimensions.get("window").width / 2 : 1,
                bottom: 400,
                width: 200,
              }
            : {}
        }
      />
    </RootWrapper>
  );
};
