import React from "react";
import {View, AppState, Platform, TouchableWithoutFeedback} from "react-native";
import {connect} from "react-redux";
import {
  GiftedChat,
  Composer,
  Time,
  Day,
  Bubble,
  InputToolbar,
  Send,
} from "react-native-gifted-chat";
import Dialog from "react-native-dialog";
import RootWrapper from "components/presentationals/RootWrapper";
import ActionsCreators from "redux/actions";
import {MaterialIcons} from "@expo/vector-icons";
import {sendMessages} from "helpers/MessagesHelper";
import LoadingSpinner from "../components/presentationals/LoadingSpinner";
import {getRealTimeMessages, setRealTimeMessagesSaved} from "helpers/ApiHelper";
import {saveMessagesToDevice} from "helpers/StoreHelper";
import AnalyticsHelper from "helpers/AnalyticsHelper";
import {Screens} from "config/settings";
import * as Notifications from "expo-notifications";

const styles = {
  textInputStyle: {
    fontSize: 21,
    color: "white",
    fontFamily: "lato-regular",
    lineHeight: 26,
  },
  bubbleTextStyle: {
    left: {
      color: "black",
      fontFamily: "lato-regular",
      fontSize: 21,
      lineHeight: 27,
    },
    right: {
      color: "white",
      fontFamily: "lato-regular",
      fontSize: 21,
      lineHeight: 27,
    },
  },
  bubbleWrapperStyleMessageNotSent: {
    right: {
      backgroundColor: "#2f396c",
      paddingTop: 8,
      opacity: 0.2,
    },
  },
  bubbleWrapperStyleMessageSynched: {
    left: {
      backgroundColor: "#cacaca",
      paddingTop: 8,
    },
    right: {
      backgroundColor: "#2f396c",
      paddingTop: 8,
      opacity: 1,
    },
  },
};

@connect((state) => ({
  messages: state.messages,
  userData: state.userData,
  hasShownUnsentMessagesDialog: state.hasShownUnsentMessagesDialog,
  newMessagesCount: state.newMessagesCount,
  loginSynchedDataParameters: state.loginSynchedDataParameters,
}))
export default class Messages extends React.Component {
  state = {
    showFirstUnsentMessageDialog: false,
    resendMessage: null,
    appState: AppState.currentState,
  };

  intervalId;

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.newMessagesCount) {
      return {hasNewMessages: true};
    }
    return prevState;
  }

  async componentDidUpdate() {
    if (this.props.newMessagesCount > 0) {
      this.props.dispatch(ActionsCreators.setNewMessagesCount(null));
    }
    if (Platform.OS === "ios") {
      await Notifications.setBadgeCountAsync(0);
    }
  }

  componentDidMount() {
    AnalyticsHelper.setPageHit(Screens.Messages);
    AppState.addEventListener("change", this._handleAppStateChange);
    this._unsubscribePullingMessages = this.props.navigation.addListener(
      "focus",
      () => {
        if (this.props.newMessagesCount > 0) {
          this.props.dispatch(ActionsCreators.setNewMessagesCount(null));
        }
        this.startPullingMessages();
      }
    );
    this._unsubscribeBlur = this.props.navigation.addListener("blur", () => {
      clearInterval(this.intervalId);
    });
  }

  startPullingMessages = () => {
    this.doGetRealTimeMessages();
    if (!this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => this.doGetRealTimeMessages(), 5000);
  };

  doGetRealTimeMessages = (hasClientLeftChat = false) => {
    getRealTimeMessages({
      userId: this.props.userData.userId,
      lastSynchedMessageServerTime:
        this.props.messages && this.props.messages.length > 0
          ? this.props.messages[0].serverTime
          : null,
      hasClientLeftChat,
    })
      .then((messages) => {
        if (messages && messages.length > 0) {
          saveMessagesToDevice(messages, null, this.props.dispatch);
          setRealTimeMessagesSaved({
            userId: this.props.userData.userId,
            lastMessageServerTime: messages[messages.length - 1].serverTime,
          });
        }
      })
      .catch(() => {});
  };

  componentWillUnmount() {
    AppState.removeEventListener("change", this._handleAppStateChange);
    this._unsubscribeBlur();
    clearInterval(this.intervalId);
    this.doGetRealTimeMessages(true);
    this._unsubscribePullingMessages();
  }

  _handleAppStateChange = (nextAppState) => {
    if (
      this.state.appState.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      this.startPullingMessages();
      if (this.props.newMessagesCount > 0) {
        this.props.dispatch(ActionsCreators.setNewMessagesCount(null));
      }
    }
    if (
      this.state.appState === "active" &&
      (nextAppState === "inactive" || nextAppState === "background")
    ) {
      clearInterval(Messages.intervalId);
      this.doGetRealTimeMessages(true);
    }
    this.setState({appState: nextAppState});
  };

  onSend = async (messages = []) => {
    const unSentMsgs = messages.map((message) => ({
      ...message,
      createdAt: null,
    }));
    this.props.dispatch(ActionsCreators.setMessages(unSentMsgs));
    this.doSendMessages(unSentMsgs);
  };

  doSendMessages = async (messages) => {
    try {
      await sendMessages(
        this.props.userData.userId,
        messages,
        this.props.dispatch
      );
      setRealTimeMessagesSaved({
        userId: this.props.userData.userId,
        lastMessageServerTime: this.props.messages[0].serverTime,
      });
    } catch (err) {
      this.setState({showFirstUnsentMessageDialog: true});
    }
  };

  renderComposer = (props) => (
    <Composer {...props} textInputStyle={styles.textInputStyle} />
  );

  renderTimeFormat = (props) => <Time {...props} timeFormat="H:mm" />;

  renderDay = (props) => <Day {...props} dateFormat="D/M/YY" />;

  renderBubble = (props) => {
    let currentMessageBubbleStyle = styles.bubbleWrapperStyleMessageNotSent;
    if (props.currentMessage.serverTime) {
      currentMessageBubbleStyle = styles.bubbleWrapperStyleMessageSynched;
    }
    return (
      <Bubble
        {...props}
        textStyle={styles.bubbleTextStyle}
        wrapperStyle={currentMessageBubbleStyle}
      />
    );
  };

  renderInputToolbar = (props) => (
    <InputToolbar
      {...props}
      containerStyle={{
        backgroundColor: "#12194d",
        borderTopWidth: 0,
      }}
    />
  );

  renderSend = (props) => (
    <Send {...props}>
      <View style={{marginBottom: 10, marginRight: 5}}>
        <MaterialIcons name="send" color="white" size={25} />
      </View>
    </Send>
  );

  renderLoading = () => (
    <LoadingSpinner visible message="Cargando los mensajes!" />
  );

  showUnsentMessageDialog = () => {
    if (
      !this.props.hasShownUnsentMessagesDialog &&
      this.state.showFirstUnsentMessageDialog
    ) {
      return (
        <Dialog.Container visible={this.state.showFirstUnsentMessageDialog}>
          <Dialog.Title>Mensaje no enviado</Dialog.Title>
          <Dialog.Description>
            Seleccioná el mensaje para reintentar
          </Dialog.Description>
          <Dialog.Button
            label="OK"
            onPress={() => {
              this.props.dispatch(
                ActionsCreators.setHasShownUnsentMessagesDialog()
              );
              this.setState({showFirstUnsentMessageDialog: false});
            }}
          />
        </Dialog.Container>
      );
    }
  };

  onPressBubble = (context, message) => {
    if (!message.serverTime) {
      const messageCopy = {...message};
      delete messageCopy.previousMessage;
      delete messageCopy.nextMessage;
      delete messageCopy.hash;
      this.setState({resendMessage: messageCopy});
    }
  };

  showResendDialog = () =>
    this.state.resendMessage && (
      <Dialog.Container visible={this.state.resendMessage !== null}>
        <Dialog.Title>Mensaje no enviado</Dialog.Title>
        <Dialog.Button
          label="Enviar"
          onPress={() => {
            this.doSendMessages([this.state.resendMessage]);
            this.setState({resendMessage: null});
          }}
        />
        <Dialog.Button
          label="Borrar"
          onPress={() => {
            this.props.dispatch(
              ActionsCreators.deleteMessage(this.state.resendMessage)
            );
            this.setState({resendMessage: null});
          }}
        />
        <Dialog.Button
          label="Cancelar"
          onPress={() => this.setState({resendMessage: null})}
        />
      </Dialog.Container>
    );

  render() {
    return (
      <TouchableWithoutFeedback>
        <RootWrapper
          style={[
            {padding: 0},
            {marginBottom: Platform.OS === "android" ? -10 : 0},
          ]}
        >
          <GiftedChat
            textInputProps={{autoFocus: true}}
            messages={this.props.messages}
            onSend={(messages) => this.onSend(messages)}
            user={{
              _id: this.props.userData.userId,
            }}
            isAnimated
            renderComposer={this.renderComposer}
            renderTime={this.renderTimeFormat}
            renderDay={this.renderDay}
            renderBubble={this.renderBubble}
            renderInputToolbar={this.renderInputToolbar}
            renderSend={this.renderSend}
            placeholder="Escribí tu mensaje"
            placeholderTextColor="white"
            renderLoading={this.renderLoading}
            onLongPress={this.onPressBubble}
          />
          {Platform.OS !== "web" && this.showUnsentMessageDialog()}
          {Platform.OS !== "web" && this.showResendDialog()}
        </RootWrapper>
      </TouchableWithoutFeedback>
    );
  }
}
