import React from "react";
import {View} from "react-native";
import {connect} from "react-redux";
import {Screens, Messages} from "config/settings";
import * as LoginHelper from "helpers/LoginHelper";
import ActionsCreators from "redux/actions";
import * as NotificationsHelper from "helpers/NotificationsHelper";
import AnalyticsHelper from "helpers/AnalyticsHelper";
import {Text} from "react-native-animatable-promise";
import {genericBackgroundColor} from "components/styles";

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: genericBackgroundColor,
    paddingLeft: 10,
    paddingRight: 10,
  },
};

@connect((state) => ({
  isAppsFirstTime: state.isAppsFirstTime,
  userId: state.userData.userId,
  password: state.userData.password,
  loginSynchedDataParameters: state.loginSynchedDataParameters,
  deviceId: state.deviceId,
  messages: state.messages,
  hasResetFirstPassword: state.hasResetFirstPassword,
  userData: state.userData,
  newMemberData: state.newMemberData,
  routines: state.routines,
}))
export default class CheckUserActivity extends React.Component {
  static isInLoggingProcess = false;

  constructor(props) {
    super(props);
    this.checkUserStatus();
  }

  componentDidMount() {
    AnalyticsHelper.setPageHit(Screens.CheckUserActivity);
  }

  checkUserStatus = async () => {
    try {
      CheckUserActivity.isInLoggingProcess = true;
      const deviceId = this.props.deviceId
        ? this.props.deviceId
        : await NotificationsHelper.getDeviceId(this.props.dispatch);
      await LoginHelper.login(
        this.props.userId,
        this.props.password,
        deviceId,
        this.props.loginSynchedDataParameters,
        this.props.messages,
        this.props.dispatch,
        {
          data: {
            userData: this.props.userData,
            hasResetFirstPassword: this.props.hasResetFirstPassword,
            newMemberData: this.props.newMemberData,
            routines: this.props.routines,
          },
        }
      );
      if (!this.props.hasResetFirstPassword) {
        this.props.dispatch(ActionsCreators.setInResetFirstPasswordMode(true));
      }
    } catch (error) {
      if (Messages.CHECK_INTERNET_CONNECTION === error) {
        // offline login
        this.props.dispatch(ActionsCreators.setIsInSession(true));
        this.props.dispatch(ActionsCreators.login());
      } else {
        this.props.dispatch(ActionsCreators.setIsInSession(false));
        this.props.dispatch(ActionsCreators.logout());
      }
    }
  };

  render() {
    return (
      <View style={styles.container}>
        {CheckUserActivity.isInLoggingProcess && (
          <Text
            useNativeDriver
            animation="pulse"
            duration={1500}
            iterationCount="infinite"
            style={{
              fontFamily: "lato-bold",
              fontSize: 20,
              color: "white",
            }}
          >
            Qué bueno que volviste{" "}
            {this.props.userData && this.props.userData.name
              ? this.props.userData.name.split(" ")[0]
              : null}
            !
          </Text>
        )}
      </View>
    );
  }
}
